import env from "#env";
import createApi from "../../../createApi";

import { getHeaders } from "#shared/components/api/utils";
import { QUERY_TAGS } from "#constants/query";
import { Cookies } from "#customTypes/types";
import { ChannelTokenResponse } from "#customTypes/channel";
import { getAxiosErrorMessage } from "#utils/serviceHelpers";
import { appBaseQuery } from "#features/common/baseQuery";

export const channelInviteTokensApi = createApi({
  reducerPath: "channelInviteTokens",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/channel-invite-tokens/`,
  }),
  tagTypes: [QUERY_TAGS.ChannelToken],
  endpoints: (builder) => ({
    fetchChannelByToken: builder.query<
      ChannelTokenResponse | { error: string },
      { token: string; cookies?: Cookies }
    >({
      queryFn: async ({ token, cookies }, _queryApi, _extraOptions, fetchWithBQ) => {
        try {
          const response = await fetchWithBQ({
            url: `${token}`,
            method: "GET",
            headers: getHeaders(cookies),
            credentials: "include",
          });

          if (response.error) {
            throw response.error;
          }

          return { data: response.data as ChannelTokenResponse };
        } catch (error) {
          const axiosError = getAxiosErrorMessage(error);

          // We don't want to log invalid token errors
          if (!axiosError?.includes("invalid token")) {
            console.error(error);
          }

          // Return a successful response with an error flag
          return { data: { error: axiosError || "Unknown error" } };
        }
      },
      serializeQueryArgs: ({ queryArgs: { token } }) => token,
      providesTags: (_result, _error, channelRef) => [
        { type: QUERY_TAGS.ChannelToken, ref: channelRef },
      ],
    }),
    joinChannelWithToken: builder.mutation<void, { token: string }>({
      query: (body) => ({
        url: `join`,
        method: "POST",
        body,
        credentials: "include",
      }),
    }),
  }),
});

export const { useFetchChannelByTokenQuery, useJoinChannelWithTokenMutation } =
  channelInviteTokensApi;
